import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/DefaultLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <blockquote>
      <p parentName="blockquote">{`„Der Zugang zu Wissen ist das Recht eines jeden Menschen, und mitzuhelfen, Wissen zu generieren, anzuwenden und zu verbreiten ist eine Verantwortung, die alle schultern müssen ...”`}</p>
      <cite>—Das Universale Haus der Gerechtigkeit</cite>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a222954596ae780fab585b0d3dc36307/e78bf/studienkreis.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAOABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEBf/EABQBAQAAAAAAAAAAAAAAAAAAAAL/2gAMAwEAAhADEAAAAVNnhK0jPEf/xAAaEAADAQADAAAAAAAAAAAAAAABAgMAERMi/9oACAEBAAEFAvTEUdTzY6dggdiX7Su//8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAEDAQE/AbWv/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAECAQE/AZGP/8QAGxAAAgIDAQAAAAAAAAAAAAAAAAECERASMWH/2gAIAQEABj8C41fBqckPWVr0t7WSx//EABoQAQADAQEBAAAAAAAAAAAAAAEAITERcZH/2gAIAQEAAT8hOk4h2msNRSk1PIN1GxbrkuvkdHewrAT/2gAMAwEAAgADAAAAEDjf/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAh/9oACAEDAQE/EAC1f//EABYRAQEBAAAAAAAAAAAAAAAAAAEAEf/aAAgBAgEBPxAlySX/xAAdEAEAAgICAwAAAAAAAAAAAAABABEhgUFhUaHh/9oACAEBAAE/EKAdqAiJQ9RfLmQHnS+YhIDLC33vct0Py61QKZgDSGmXCQ5y9fJ//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "studienkreis",
            "title": "studienkreis",
            "src": "/static/a222954596ae780fab585b0d3dc36307/e5166/studienkreis.jpg",
            "srcSet": ["/static/a222954596ae780fab585b0d3dc36307/f93b5/studienkreis.jpg 300w", "/static/a222954596ae780fab585b0d3dc36307/b4294/studienkreis.jpg 600w", "/static/a222954596ae780fab585b0d3dc36307/e5166/studienkreis.jpg 1200w", "/static/a222954596ae780fab585b0d3dc36307/d9c39/studienkreis.jpg 1800w", "/static/a222954596ae780fab585b0d3dc36307/df51d/studienkreis.jpg 2400w", "/static/a222954596ae780fab585b0d3dc36307/e78bf/studienkreis.jpg 3336w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Immer mehr Menschen sind sich dessen bewusst, dass sich unsere Gesellschaft in einer Zeit des Umbruchs und der Orientierungssuche befindet. Daher bietet die Bahá’í-Gemeinde `}<a parentName="p" {...{
        "href": "https://www.bahai.de/gemeinsames-handeln/gemeinsames-lernen/das-trainingsinstitut/"
      }}>{`Studienkreise`}</a>{` an, die helfen sollen, über das eigene Leben nachzudenken und zu reflektieren. Auf der Basis von gemeinsam gelesenen Texten aus den Bahá’í-Schriften bieten die Studienkreise Gelegenheit, neue Einsichten zu gewinnen und zu einem tieferen Verständnis der geistigen Wirklichkeit eines jeden Menschen zu gelangen. Sie behandeln Themen wie z. B. das Gebet, das Wesen der Seele, den Sinn unseres Lebens, die Aufgabe der göttlichen Boten oder der Zustand unserer Seele nach dem Tod. Sie beschäftigen sich auch mit der Frage, wie wir den gegenwärtigen Herausforderungen unseres Lebens angemessen begegnen können.`}</p>
    <p>{`Bahá’í-Studienkreise sind ein Angebot für alle interessierten Menschen, für alle Religionen und Weltanschauungen. Bitte kontaktieren Sie uns, falls Sie Fragen zu Inhalten und Aufbau der Studienkreise haben.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      